<template>
  <Drawer :drawer="drawer" title="报价" :size="950" @close="handleClose">
    <div v-loading="loading" class="view">
      <div class="panel" style="margin-bottom: 40px">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-row :gutter="20">
            <el-col :span="7.5">
              <el-form-item label="报价" prop="price">
                <el-input
                  v-model="form.price"
                  oninput="value=value.replace(/[^\d\.?]/g,'')"
                  placeholder="请输入"
                  style="width: 275px;"
                >
                  <div slot="suffix" class="suffix">USD</div>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7.5">
              <el-form-item label="预估时效" prop="limitation">
                <el-input
                  v-model="form.limitation"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="请输入"
                  style="width: 275px;"
                >
                  <div slot="suffix" class="suffix">天</div>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8.5">
              <el-form-item label="报价有效期" prop="validityDate">
                <el-date-picker
                  v-model="form.validityDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 320px;"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :style="{width: rowData.pickUpType === 1 ? '410px' : '750px'}">
              <el-form-item label="报价备注" prop="remarks">
                <el-input
                  v-model="form.remarks"
                  placeholder="请输入"
                  :style="{width: rowData.pickUpType === 1 ? '410px' : '750px'}"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="rowData.pickUpType === 1" :style="{width: rowData.pickUpType === 1 ? '410px' : '750px', display: 'flex'}">
              <el-form-item
                label="上传附件"
                prop="fileId"
                style="margin-left: 20px;"
              >
                <el-upload
                  :action="`/backEnd/api/${config.supplyCode}/File/uploadFile?source=9`"
                  :file-list="fileList"
                  :show-file-list="false"
                  :on-success="onSuccess"
                  :on-error="onError"
                  :before-upload="beforeUpload"
                >
                  <div class="upload">
                    <img src="@/assets/images/bill/upload-1.png" alt="" />
                    选择文件上传
                  </div>
                </el-upload>
              </el-form-item>
              <div
                v-if="file"
                class="fileList"
              >
                <div style="flex: 1; display: flex; align-items: center">
                  <div class="flex_c_c">
                    <el-progress
                      type="circle"
                      :width="40"
                      :height="40"
                      :stroke-width="4"
                      :percentage="100"
                    ></el-progress>
                  </div>
                  <div class="file">
                    <p class="fileName">{{ file.name }}</p>
                    <p class="fileSize">{{ file.size | formatSize }} mb</p>
                  </div>
                  <div class="delete" title="删除" @click="deleteFile">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </el-col>
            <div v-loading="loading" :style="{width: rowData.pickUpType === 1 ? '100px' : '140px'}" class="add flex_c_c" @click="add('form')">新增报价</div>
          </el-row>
        </el-form>
      </div>
      <div class="panel">
        <div class="panel_title flex_c_c">报价结果</div>
        <template v-for="(item, $index) in priceList">
          <div :key="$index" class="price_item flex_c_c">
            <div class="price_data">
              <span class="price_title">
                <img src="@/assets/images/order/date.png">预估时效
              </span>
              <span class="price_info">
                {{ item.limitation }} 天
              </span>
            </div>
            <div class="price_validity">
              <span class="price_title">
                <img src="@/assets/images/order/validity.png">报价有效期
              </span>
              <span class="price_info">
                {{ item.validityDateStart | formatDate }} ~ {{ item.validityDateEnd | formatDate }}
              </span>
            </div>
            <div class="price_remarks">
              <span class="price_title">
                <img src="@/assets/images/order/remark.png">报价备注
              </span>
              <span class="price_info">
                {{ item.remarks }}
              </span>
            </div>
            <div class="price_offer">
              <span class="price_title">
                <img src="@/assets/images/order/offer.png">报价{{ rowData.pickUpType === 1 ? '(纯运费)' : '' }}
              </span>
              <span class="price_info">
                $ {{ item.price }}
              </span>
              <div v-if="rowData.pickUpType === 1" class="price_file">
                <span @click="download(item.fileGuid)"><img src="@/assets/images/bill/download.png">下载</span>
                <span @click="preview(item.fileGuid)"><i class="el-icon-zoom-in"></i>预览</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import {
  addTruckQuotePrice,
  getTruckQuotePriceList
} from '@/api/fee'
import config from '@/utils/config'
export default {
  components: {
    Drawer
  },
  filters: {
    formatDate(v) {
      return v ? v.slice(0, v.length - 9) : ''
    },
    formatSize(size) {
      return (size / 1024 / 1024).toFixed(2)
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      config,
      form: {
        price: '',
        validityDate: '',
        limitation: '',
        remarks: '',
        fileId: ''
      },
      rules: {
        price: [
          { required: true, message: '请输入报价价格', trigger: 'blur' }
        ],
        validityDate: [
          { required: true, message: '请选择报价有效期', trigger: 'blur' }
        ],
        limitation: [
          { required: true, message: '请输入预估时效', trigger: 'blur' }
        ],
        fileId: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ]
      },
      loading: false,
      priceList: [],
      fileList: [],
      file: null
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    handleClose() {
      this.$refs['form'].resetFields()
      this.$emit('close')
    },
    add(formName) {
      this.loading = true
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const params = {
              ...this.form,
              truckQuoteId: this.rowData.id,
              validityDateStart: this.form.validityDate[0],
              validityDateEnd: this.form.validityDate[1]
            }
            const res = await addTruckQuotePrice(params)
            this.loading = false
            if (res.success) {
              this.$refs[formName].resetFields()
              this.$message.success(res.errorMessage)
              this.deleteFile()
              this.getList()
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            this.loading = false
            console.error(error)
          }
        } else {
          this.loading = false
          return false
        }
      })
    },
    async getList() {
      try {
        const res = await getTruckQuotePriceList({ truckQuoteId: this.rowData.id })
        if (res.success) {
          this.priceList = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    deleteFile() {
      this.fileList = []
      this.file = null
      this.form.fileId = ''
    },
    onSuccess(res) {
      if (!res.success) {
        this.$message.error(res.errorMessage)
        this.fileList = []
      } else {
        this.form.fileId = res.data.fileId
      }
    },
    onError(error) {
      this.$message.error('上传失败')
      console.error(error)
    },
    beforeUpload(file) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (suffix !== 'pdf' && suffix !== 'jpeg' && suffix !== 'jpg' && suffix !== 'png') {
        this.$message.error('仅支持上传图片和PDF文件')
        return false
      }
      this.file = file
    },
    download(id) {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href =
          `/backEnd/api/${config.supplyCode}/File/downLoadFile?guid=` + id
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    },
    preview(id) {
      window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-drawer__body {
  overflow: overlay;
}
.view {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  box-sizing: border-box;
  .panel {
    width: 910px;
    .upload {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      margin-right: 10px;
      box-sizing: border-box;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
    }
    .fileList {
      width: auto;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      .file {
        margin-left: 10px;
      }
      .fileName {
        width: 112px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 16px;
      }
      .fileSize {
        line-height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .delete {
        font-size: 18px;
        color: #2395F3;
        cursor: pointer;
      }
    }
    .el-row {
      display: flex;
    }
    .add {
      width: 140px;
      height: 40px;
      background: #35BA83;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      margin-top: 30px;
      margin-left: 20px;
    }
    .panel_title {
      width: 120px;
      height: 30px;
      background: #2395F3;
      border-radius: 4px 15px 15px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
    .price_item {
      width: 100%;
      height: 140px;
      background: #FFFFFF;
      box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
      border-radius: 4px 4px 4px 4px;
      padding: 16px;
      box-sizing: border-box;
      & + .price_item {
        margin-top: 20px;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        img {
          margin-right: 4px;
        }
      }
      .price_data {
        position: relative;
        width: 164px;
        &::after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 40px;
          border-left: 1px solid #EEEEEE;
        }
        .price_info {
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #35BA83;
        }
      }
      .price_validity {
        position: relative;
        width: 245px;
        &::after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 40px;
          border-left: 1px solid #EEEEEE;
        }
        .price_info {
          font-size: 14px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #2395F3;
        }
      }
      .price_remarks {
        flex: 1;
        .price_info {
          font-size: 14px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #666666;
        }
      }
      .price_offer {
        width: 160px;
        background: #F7F7F7;
        border-radius: 4px 4px 4px 4px;
        .price_info {
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #FE453D;
        }
        .price_file {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 140px;
          height: 24px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #2395F3;
          & span {
            display: flex;
            align-items: center;
            cursor: pointer;
            & i {
              font-size: 16px;
            }
          }
        }
      }
      .price_title {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .suffix {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
  }
}
</style>
