<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="item in config.cardInquiryStatusOptions"
          :key="item.value"
          v-permissson="`${item.permissson}`"
          class="tabItem"
          :class="{ active: activeName === item.value }"
          @click="handleClick(item.value, item.permissson)"
        >{{ item.value === -1 ? `${item.label}(${tabTotal})` : `${item.label}(${tabNumer?.find(a => a.type === +item.value)?.qty || 0})` }}
        </span>
      </div>
      <div class="opt">
        <div class="search">
          <el-input
            v-model="searchVal"
            placeholder="请输入邮编号码"
            class="input-search"
            @keydown.enter.native="search"
          ></el-input>
          <div class="btn-search" @click="search">
            <i class="el-icon-search"></i>
          </div>
        </div>
        <div
          v-show="cardInquiry"
          :v-permissson="cardInquiry"
          class="btn flex_c_c"
          @click="inquiry"
        >
          <img src="@/assets/images/order/rmb.png">卡派询价
        </div>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :tableData="{
          ...tableData,
          fileds
        }"
        :height="height"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="pickUpAddress" slot-scope="row">
          <!-- <div>{{ row.scope.pickUpAddress.address1 }}</div> -->
          <div v-html="formatAddress(row.scope.pickUpAddress)"></div>
        </template>
        <template slot="arrivedAddress" slot-scope="row">
          <!-- <div>{{ row.scope.arrivedAddress.address1 }}</div> -->
          <div v-html="formatAddress(row.scope.arrivedAddress)"></div>
        </template>
        <template slot="status" slot-scope="row">
          <div :class="['status', `status${row.scope.status}`, 'flex_c_c']">{{ row.scope.status | formatStatus }}</div>
        </template>
        <template slot="opt" slot-scope="row">
          <div v-show="activeName === -1">
            <el-button
              v-if="row.scope.status !== 10"
              v-permissson="'offer_a'"
              type="text"
              size="small"
              @click="offerRow(row.scope)"
            >报价</el-button
            >
            <el-button
              v-permissson="'detail_a'"
              type="text"
              size="small"
              @click="detailRow(row.scope)"
            >详情</el-button
            >
          </div>
          <div v-show="activeName === 0">
            <el-button
              v-permissson="'offer_a'"
              type="text"
              size="small"
              @click="offerRow(row.scope)"
            >报价</el-button
            >
            <el-button
              v-permissson="'detail_a'"
              type="text"
              size="small"
              @click="detailRow(row.scope)"
            >详情</el-button
            >
          </div>
          <div v-show="activeName === 5">
            <el-button
              v-permissson="'offer_a'"
              type="text"
              size="small"
              @click="offerRow(row.scope)"
            >报价</el-button
            >
            <el-button
              v-permissson="'detail_a'"
              type="text"
              size="small"
              @click="detailRow(row.scope)"
            >详情</el-button
            >
          </div>
          <div v-show="activeName === 10">
            <el-button
              v-permissson="'detail_a'"
              type="text"
              size="small"
              @click="detailRow(row.scope)"
            >详情</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <CardInquiryDrawer
      :drawer="drawer"
      :rowData="rowData"
      :shipOptions="shipOptions"
      :airOptions="airOptions"
      :amsWarehouseList="amsWarehouseList"
      :isAdd="isAdd"
      @close="close"
      @ok="ok"
    ></CardInquiryDrawer>
    <OfferDrawer
      :drawer="drawer1"
      :rowData="rowData1"
      @close="close"
      @ok="ok"
    ></OfferDrawer>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import CardInquiryDrawer from './components/CardInquiryDrawer.vue'
import OfferDrawer from './components/OfferDrawer.vue'
import config from '@/utils/config'
import { permissions } from '@/utils/common'
import {
  getTruckQuotePages
} from '@/api/fee'
import {
  openSearchAddressBooks
} from '@/api/basic'
import { myMixins } from '@/mixins'
export default {
  name: 'CardInquiry',
  components: {
    BaseTable,
    CardInquiryDrawer,
    OfferDrawer
  },
  filters: {
    formatStatus(v) {
      let str
      switch (v) {
        case 0:
          str = '未报价'
          break
        case 5:
          str = '已报价'
          break
        case 10:
          str = '已使用'
          break
      }
      return str
    }
  },
  mixins: [myMixins],
  data() {
    return {
      config,
      activeName: 10000,
      activeCode: '',
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      tableData: {
        fileds: [
          {
            filed: 'number',
            label: '询价单号',
            width: '200',
            fixed: 'left'
          },
          {
            filed: 'customerName',
            label: '客户名称',
            width: '230'
          },
          {
            filed: 'type',
            label: '类型',
            width: '80',
            formatter: (row) => {
              let str
              switch (row.type) {
                case 0:
                  str = '海运'
                  break
                case 1:
                  str = '空运'
                  break
                default:
                  str = '其他'
                  break
              }
              return str
            }
          },
          {
            filed: 'transportCode',
            label: '港口/码头',
            width: '95'
          },
          {
            filed: 'pickUpAddress',
            label: '提货地址',
            width: '300',
            isCustom: true
          },
          {
            filed: 'arrivedAddress',
            label: '派送地址',
            width: '300',
            isCustom: true
          },
          {
            filed: 'pickUpType',
            label: '派送方式',
            width: '100',
            formatter: (row) => {
              let str
              switch (row.pickUpType) {
                case 0:
                  str = '自提'
                  break
                case 1:
                  str = '直送'
                  break
                case 2:
                  str = '回仓'
                  break
              }
              return str
            }
          },
          {
            filed: 'truckType',
            label: '卡车类型',
            width: '80'
          },
          {
            filed: 'createdName',
            label: '业务员',
            width: '80'
          },
          {
            filed: 'arrivedAddressType',
            label: '派送地址区域类型',
            width: '170'
          },
          {
            filed: 'isTailboard',
            label: '是否需要尾板',
            width: '90',
            formatter: (row) =>
              row.formatter ? '是' : '否'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '100',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      loading: false,
      drawer: false,
      drawer1: false,
      shipOptions: [],
      airOptions: [],
      amsWarehouseList: [],
      tabNumer: [],
      rowData: {},
      rowData1: {},
      isAdd: true,
      tabTotal: 0,
      searchVal: ''
    }
  },
  computed: {
    cardInquiry() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '卡派询价')?.code
      return code || ''
    },
    fileds() {
      const arr = [...this.tableData.fileds]
      const obj = {
        filed: 'status',
        label: '状态',
        width: '90',
        isCustom: true
      }
      if (this.activeName === -1) {
        arr.splice(arr.length - 1, 0, obj)
      }
      return arr
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = [
        [(name) => name === 'QB', () => -1],
        [(name) => name === 'WBJ', () => 0],
        [(name) => name === 'YBJ', () => 5],
        [(name) => name === 'YSY', () => 10]
      ]
      this.activeName = codeLits.find((a) => a[0](menu.children[0].code))[1]()
      this.activeCode = menu.children[0].code
      this.getList(this.pagerConfig)
      this.getTransportOptions()
      this.getAmsWarehouseList()
    }
  },
  methods: {
    handleClick(value, code) {
      this.activeName = value
      this.activeCode = code
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.getList(param)
    },
    async getList(params) {
      this.loading = true
      params = {
        ...params,
        queryParam: {
          status: this.activeName !== -1 ? this.activeName : undefined,
          postalCode: this.searchVal || undefined
        }
      }
      try {
        const res = await getTruckQuotePages(params)
        this.loading = false
        if (res.success) {
          this.tabNumer = res.data.tabNumer
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.tabTotal = res.data.tabTotal
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    async getTransportOptions() {
      const arr1 = await this.$store.dispatch('getHarborDrapDown', {
        portType: 0,
        type: 1
      })
      const arr2 = await this.$store.dispatch('getHarborDrapDown', {
        portType: 1,
        type: 1
      })
      this.shipOptions = arr2.map(a => {
        const values = a.label.split('-')
        const label = `${values[1]}-${values[2]}`
        return {
          ...a,
          value: `${label}`,
          label: label
        }
      })
      this.airOptions = arr1.map(a => {
        const values = a.label.split('-')
        const label = `${values[1]}-${values[2]}`
        return {
          ...a,
          value: `${label}`,
          label: label
        }
      })
    },
    handlePageChange(pager) {
      this.pagerConfig = pager
      this.getList(pager)
    },
    inquiry() {
      this.drawer = true
      this.isAdd = true
    },
    async getAmsWarehouseList() {
      try {
        const res = await openSearchAddressBooks({
          pageIndex: 1,
          pageSize: 1000,
          queryParam: {
            type: 1
          }
        })
        if (res.success) {
          const arr = res.data.list
            .sort((a, b) => a.code.localeCompare(b.code))
            .map((item) => {
              return {
                ...item,
                value: item.code,
                label: `${item.code}-${item.countryName}`
              }
            })
          this.amsWarehouseList = arr
        }
      } catch (error) {
        console.log(error)
      }
    },
    ok() {
      this.drawer = false
      this.getList(this.pagerConfig)
    },
    close() {
      this.drawer = false
      this.drawer1 = false
      this.rowData = {}
      this.rowData1 = {}
    },
    detailRow(row) {
      this.drawer = true
      this.rowData = row
      this.isAdd = false
    },
    offerRow(row) {
      this.drawer1 = true
      this.rowData1 = row
    },
    formatAddress(v) {
      if (!v?.address1 && !v?.city && !v?.stateProvinceCode && !v?.postalCode && !v?.code) {
        return ''
      } else {
        const { address1, city, stateProvinceCode, postalCode } = v
        const code = v.code ? v.code.split('-')[0] : ''
        return `${address1}<br>${city},${stateProvinceCode}, <strong>${postalCode}</strong> ${code ? `, <strong>${code}</strong>` : ''}`
      }
    },
    search() {
      this.getList(this.pagerConfig)
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabItem {
      padding: 20px;
      width: 42px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
    }
    .active {
      color: #2395f3;
    }
    .opt {
      display: flex;
      .search {
        position: relative;
        display: flex;
        width: 200px;
        margin-right: 8px;
        .input-search {
          width: 166px;
          height: 30px !important;
          /deep/ .el-input__inner {
            height: 30px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .btn-search {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border: 1px solid #dbe4f2;
          border-left: 0;
          border-radius: 0 4px 4px 0;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
      .btn {
        width: 120px;
        height: 30px;
        background: #FEAA4F;
        border-radius: 2px 2px 2px 2px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .status {
      width: 50px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      margin: 0 auto;
    }
    .status0 {
      background: rgba(254, 170, 79, .1);
      color: #FEAA4F;
    }
    .status5 {
      background: rgba(35, 149, 243, .1);
      color: #2395F3;
    }
    .status10 {
      background: rgba(53, 186, 131, .1);
      color: #35BA83;
    }
  }
}
</style>
