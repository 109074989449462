var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "报价", size: 950 },
      on: { close: _vm.handleClose },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "view",
        },
        [
          _c(
            "div",
            { staticClass: "panel", staticStyle: { "margin-bottom": "40px" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "报价", prop: "price" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "275px" },
                                  attrs: {
                                    oninput:
                                      "value=value.replace(/[^\\d\\.?]/g,'')",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.form.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "price", $$v)
                                    },
                                    expression: "form.price",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "suffix",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [_vm._v("USD")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7.5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "预估时效", prop: "limitation" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "275px" },
                                  attrs: {
                                    oninput:
                                      "value=value.replace(/[^\\d]/g,'')",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.form.limitation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "limitation", $$v)
                                    },
                                    expression: "form.limitation",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "suffix",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [_vm._v("天")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8.5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报价有效期",
                                prop: "validityDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.validityDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "validityDate", $$v)
                                  },
                                  expression: "form.validityDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          style: {
                            width:
                              _vm.rowData.pickUpType === 1 ? "410px" : "750px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "报价备注", prop: "remarks" } },
                            [
                              _c("el-input", {
                                style: {
                                  width:
                                    _vm.rowData.pickUpType === 1
                                      ? "410px"
                                      : "750px",
                                },
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.rowData.pickUpType === 1
                        ? _c(
                            "el-col",
                            {
                              style: {
                                width:
                                  _vm.rowData.pickUpType === 1
                                    ? "410px"
                                    : "750px",
                                display: "flex",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { label: "上传附件", prop: "fileId" },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: `/backEnd/api/${_vm.config.supplyCode}/File/uploadFile?source=9`,
                                        "file-list": _vm.fileList,
                                        "show-file-list": false,
                                        "on-success": _vm.onSuccess,
                                        "on-error": _vm.onError,
                                        "before-upload": _vm.beforeUpload,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upload" }, [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/bill/upload-1.png"),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" 选择文件上传 "),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.file
                                ? _c("div", { staticClass: "fileList" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          flex: "1",
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex_c_c" },
                                          [
                                            _c("el-progress", {
                                              attrs: {
                                                type: "circle",
                                                width: 40,
                                                height: 40,
                                                "stroke-width": 4,
                                                percentage: 100,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "file" }, [
                                          _c("p", { staticClass: "fileName" }, [
                                            _vm._v(_vm._s(_vm.file.name)),
                                          ]),
                                          _c("p", { staticClass: "fileSize" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatSize")(
                                                  _vm.file.size
                                                )
                                              ) + " mb"
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "delete",
                                            attrs: { title: "删除" },
                                            on: { click: _vm.deleteFile },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "add flex_c_c",
                          style: {
                            width:
                              _vm.rowData.pickUpType === 1 ? "100px" : "140px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.add("form")
                            },
                          },
                        },
                        [_vm._v("新增报价")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "panel" },
            [
              _c("div", { staticClass: "panel_title flex_c_c" }, [
                _vm._v("报价结果"),
              ]),
              _vm._l(_vm.priceList, function (item, $index) {
                return [
                  _c(
                    "div",
                    { key: $index, staticClass: "price_item flex_c_c" },
                    [
                      _c("div", { staticClass: "price_data" }, [
                        _c("span", { staticClass: "price_title" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/order/date.png"),
                            },
                          }),
                          _vm._v("预估时效 "),
                        ]),
                        _c("span", { staticClass: "price_info" }, [
                          _vm._v(" " + _vm._s(item.limitation) + " 天 "),
                        ]),
                      ]),
                      _c("div", { staticClass: "price_validity" }, [
                        _c("span", { staticClass: "price_title" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/order/validity.png"),
                            },
                          }),
                          _vm._v("报价有效期 "),
                        ]),
                        _c("span", { staticClass: "price_info" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatDate")(item.validityDateStart)
                              ) +
                              " ~ " +
                              _vm._s(
                                _vm._f("formatDate")(item.validityDateEnd)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "price_remarks" }, [
                        _c("span", { staticClass: "price_title" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/order/remark.png"),
                            },
                          }),
                          _vm._v("报价备注 "),
                        ]),
                        _c("span", { staticClass: "price_info" }, [
                          _vm._v(" " + _vm._s(item.remarks) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "price_offer" }, [
                        _c("span", { staticClass: "price_title" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/order/offer.png"),
                            },
                          }),
                          _vm._v(
                            "报价" +
                              _vm._s(
                                _vm.rowData.pickUpType === 1 ? "(纯运费)" : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "price_info" }, [
                          _vm._v(" $ " + _vm._s(item.price) + " "),
                        ]),
                        _vm.rowData.pickUpType === 1
                          ? _c("div", { staticClass: "price_file" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(item.fileGuid)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/bill/download.png"),
                                    },
                                  }),
                                  _vm._v("下载"),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.preview(item.fileGuid)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-zoom-in" }),
                                  _vm._v("预览"),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }