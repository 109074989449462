<template>
  <Drawer :drawer="drawer" :withHeader="false" :size="970" @close="handleClose">
    <div class="view">
      <div class="title" :style="{padding: isAdd ? '0 20px' : '0 20px 0 0'}">
        <span v-if="isAdd">卡派询价</span>
        <div v-else class="tag">
          <el-tabs v-model="active" type="card">
            <el-tab-pane label="详情" name="0"></el-tab-pane>
            <el-tab-pane
              v-if="rowData.status !== 0"
              label="报价"
              name="1"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <i class="el-icon-close closeIcon" @click="handleClose" />
      </div>
      <div class="log-body">
        <div v-loading="loading" class="content">
          <template v-if="active === '0'">
            <el-form
              ref="form1"
              :model="form"
              :rules="rules"
              label-position="top"
            >
              <div class="panel">
                <div class="panel_title flex_c_c">选择派送方式</div>
                <el-row>
                  <el-col :span="8">
                    <el-form-item prop="pickUpType">
                      <el-radio
                        v-model="form.pickUpType"
                        :label="1"
                        :disabled="disabled"
                      >直送</el-radio
                      >
                      <el-radio
                        v-model="form.pickUpType"
                        :label="2"
                        :disabled="disabled"
                      >回仓</el-radio
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="panel line" style="margin-bottom: 20px">
                <div class="panel_title flex_c_c">填写卡派信息</div>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="选择类型" prop="type">
                      <div class="tab">
                        <div
                          v-for="(item, $index) in tabItems"
                          :key="$index"
                          :class="['radio', form.type === $index ? 'active' : '']"
                          @click="handleClickTab($index)"
                        >
                          <svg-icon className="icon" :iconClass="item.icon" />
                          <span>{{ item.name }}</span>
                          <span
                            class="round"
                          ><img
                            src="@/assets/images/order/selected.png"
                          /></span>
                        </div>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="客户是否开户" prop="isCustomer">
                      <el-radio-group
                        v-model="form.isCustomer"
                        @input="handleCustomer"
                      >
                        <el-radio :label="true" :disabled="disabled">已开户</el-radio>
                        <el-radio :label="false" :disabled="disabled">未开户</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="客户名称" prop="customerName">
                      <el-select
                        v-if="form.isCustomer"
                        v-model="form.customerName"
                        filterable
                        style="width: 100%"
                        placeholder="请选择"
                        :disabled="disabled"
                        @change="customerChange"
                      >
                        <el-option
                          v-for="item in companyDrapdown"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        v-else
                        v-model="form.customerName"
                        placeholder="请输入"
                        :disabled="disabled"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item :label="!form.type ? '港口' : '机场'" prop="transportName">
                      <el-select
                        v-model="form.transportName"
                        placeholder="请选择"
                        style="width: 100%"
                        :disabled="disabled"
                        @change="handleTransport"
                      >
                        <el-option
                          v-for="item in transportOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="form.pickUpType === 2" :span="8">
                    <el-form-item label="卡车类型" prop="truckType">
                      <el-radio-group
                        v-model="form.truckType"
                        @input="handletruckType"
                      >
                        <el-radio label="LTL" :disabled="disabled">LTL</el-radio>
                        <el-radio label="53'FTL" :disabled="disabled">53'FTL</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="form.pickUpType === 2" :span="8">
                    <el-form-item label="是否需要尾板" prop="isTailboard">
                      <el-switch
                        v-model="form.isTailboard"
                        :active-text="form.isTailboard ? '是' : '否'"
                        :disabled="form.truckType === `53'FTL` || disabled"
                      >
                      </el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
            <div
              v-show="showPanel && form.pickUpType === 2"
              class="panel line"
              style="margin-bottom: 40px"
            >
              <div class="panel_title flex_c_c">提货地址</div>
              <div class="pickup_box">
                <div>{{ pickUpAddress.address1 }}</div>
                <div>
                  {{
                    pickUpAddress.city +
                      "," +
                      pickUpAddress.stateProvinceCode +
                      ","
                  }}
                  <strong>{{ pickUpAddress.postalCode }}</strong>
                </div>
              </div>
            </div>
            <div
              v-show="showPanel"
              class="panel line"
              style="margin-bottom: 20px"
            >
              <div class="panel_title flex_c_c">派送地址</div>
              <div style="margin-bottom: 10px">
                <el-radio-group v-model="isAmazon">
                  <el-radio :label="true" :disabled="disabled">亚马逊仓</el-radio>
                  <el-radio :label="false" :disabled="disabled">非亚马逊仓</el-radio>
                </el-radio-group>
              </div>
              <el-form
                ref="form2"
                :model="arrivedAddress"
                :rules="arrivedRules"
                label-position="top"
              >
                <template v-if="isAmazon">
                  <el-form-item
                    label="仓库代码"
                    prop="code"
                    style="margin-bottom: 40px"
                  >
                    <custom-select
                      v-model="arrivedAddress.code"
                      :selectItems="amsWarehouseList"
                      style="width: 445px"
                      :disabled="disabled"
                      dropdownWidth="445"
                      placeholder="请选择"
                      label="仓库编码"
                    ></custom-select>
                  </el-form-item>
                </template>
                <template v-else>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="邮编" prop="postalCode">
                        <el-autocomplete
                          v-model="arrivedAddress.postalCode"
                          style="width: 290px"
                          oninput="value=value.replace(/[^0-9a-zA-Z-]/g,'')"
                          placeholder="输入数字、英文和横杠字符，最多12位"
                          :maxlength="12"
                          :fetch-suggestions="querySearchAsync"
                          :trigger-on-focus="false"
                          :disabled="disabled"
                          @select="handleSelect"
                        >
                          <template slot-scope="{ item }">
                            <div class="name">{{ item.value }}</div>
                            <span class="addr">{{ item.address }}</span>
                          </template>
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="省州" prop="stateProvinceCode">
                        <el-input
                          v-model="arrivedAddress.stateProvinceCode"
                          placeholder="请输入省州"
                          :disabled="disabled"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="城市" prop="city">
                        <el-input
                          v-model="arrivedAddress.city"
                          placeholder="请输入城市"
                          :disabled="disabled"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="16">
                      <el-form-item label="详细地址" prop="address1">
                        <el-input
                          v-model="arrivedAddress.address1"
                          placeholder="请输入详细地址"
                          style="width: 600px"
                          :disabled="disabled"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-checkbox-group
                        v-model="checkedArrived"
                        class="arrived"
                      >
                        <div>
                          <el-checkbox
                            ref="arrivedAddress1"
                            label="商业地址带装卸台"
                            :disabled="disabled"
                            @change="(v) => handleArrivedChange(v, '商业地址带装卸台')"
                          ></el-checkbox
                          >
                          <el-checkbox
                            ref="arrivedAddress2"
                            label="住宅地址"
                            :disabled="form.truckType === `53'FTL` || disabled"
                            @change="(v) => handleArrivedChange(v, '住宅地址')"
                          ></el-checkbox
                          >
                        </div>
                        <div>
                          <el-checkbox
                            ref="arrivedAddress3"
                            label="商业地址不带装卸台"
                            :disabled="disabled"
                            @change="(v) => handleArrivedChange(v, '商业地址不带装卸台')"
                          ></el-checkbox
                          >
                          <el-checkbox
                            ref="arrivedAddress4"
                            label="CFS仓库"
                            :disabled="disabled"
                            @change="(v) => handleArrivedChange(v, 'CFS仓库')"
                          ></el-checkbox>
                        </div>
                      </el-checkbox-group>
                    </el-col>
                  </el-row>
                </template>
              </el-form>
            </div>
            <div
              v-show="showPanel"
              class="panel line"
              style="margin-bottom: 20px"
            >
              <div class="panel_header">
                <div class="panel_title flex_c_c" style="margin: 0">货物信息</div>
                <div v-if="!disabled && form.pickUpType === 2" class="newadd flex_c_c" @click="newadd">
                  <img src="@/assets/images/order/addimg.png" />
                  新增
                </div>
              </div>
              <template v-if="form.pickUpType === 2">
                <template v-for="(item, $index) in productList">
                  <div :key="$index" class="product_list">
                    <div
                      v-if="$index && !disabled"
                      class="delete-goods flex_c_c"
                      title="删除"
                      @click="deleteProductL($index)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                    <el-form
                      :ref="`product${$index}`"
                      :model="productList[$index]"
                      :rules="productRules"
                      label-position="top"
                    >
                      <div class="product_panel">
                        <el-form-item
                          label="品名"
                          prop="productName"
                        >
                          <el-input
                            v-model="item.productName"
                            placeholder="请输入品名"
                            :disabled="disabled"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="包装类型"
                          prop="packingCode"
                          style="width: 115px;"
                        >
                          <el-radio-group v-model="item.packingCode" @input="(v) => handlePacking(v, item)">
                            <el-radio label="PLT" :disabled="disabled">板</el-radio>
                            <el-radio label="PKG" :disabled="disabled">箱</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        <el-form-item
                          v-if="item.packingCode === 'PLT'"
                          label="卡板类型"
                          prop="palletType"
                          style="width: 180px;"
                        >
                          <el-select
                            v-model="item.palletType"
                            placeholder="请选择"
                            :disabled="disabled"
                            @change="handleSelectKb($index, item)"
                          >
                            <el-option
                              v-for="keys in kabanOptions"
                              :key="keys.value"
                              :label="keys.label"
                              :value="keys.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          label="单位"
                          prop="unitType"
                          style="width: 135px;"
                        >
                          <el-select
                            v-model="item.unitType"
                            placeholder="请选择"
                            :disabled="disabled"
                            @change="() => computeCm(item)"
                          >
                            <el-option
                              v-for="keys in unitTypeOptions"
                              :key="keys.value"
                              :label="keys.label"
                              :value="keys.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          label="是否危险品"
                          prop="isDangerous"
                          style="width: 130px;"
                        >
                          <el-switch
                            v-model="item.isDangerous"
                            :disabled="disabled"
                            :active-text="item.isDangerous ? '是' : '否'">
                          </el-switch>
                        </el-form-item>
                      </div>
                      <div class="product_panel">
                        <el-form-item
                          label="数量"
                          prop="qty"
                          style="width: 110px;margin-right: 0px;"
                        >
                          <el-input
                            v-model="item.qty"
                            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                            placeholder="请输入"
                            :disabled="disabled"
                            @input="() => computeCm(item)"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          label="单件重量"
                          prop="weight"
                          style="width: 110px;"
                        >
                          <el-input
                            v-model="item.weight"
                            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                            placeholder="请输入"
                            :disabled="disabled"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          label="总重量"
                          style="width: 110px;"
                        >
                          <el-input
                            :value="item.qty * item.weight"
                            disabled
                          >
                          </el-input>
                        </el-form-item>
                        <div class="input">
                          <label><span class="require">* </span>尺寸</label>
                          <div class="widthMax">
                            <el-form-item
                              prop="length"
                              style="width: 80px;margin: 0;"
                            >
                              <el-input
                                v-model="item.length"
                                placeholder="长"
                                class="input1"
                                :disabled="item.palletType !== '自定义' || disabled"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                @input="() => computeCm(item)"
                              >
                              </el-input>
                            </el-form-item>
                            <el-form-item
                              prop="width"
                              style="width: 80px;margin: 0;"
                            >
                              <el-input
                                v-model="item.width"
                                placeholder="宽"
                                class="input1 input2"
                                :disabled="item.palletType !== '自定义' || disabled"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                @input="() => computeCm(item)"
                              >
                              </el-input>
                            </el-form-item>
                            <el-form-item
                              prop="height"
                              style="width: 80px;margin: 0;"
                            >
                              <el-input
                                v-model="item.height"
                                placeholder="高"
                                class="input2 input1"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                :disabled="disabled"
                                @input="() => computeCm(item)"
                              >
                              </el-input>
                            </el-form-item>
                            <div class="suffix flex_c_c">{{ item.unitType ? 'CM' : 'IN' }}</div>
                          </div>
                        </div>
                        <el-form-item
                          label="方数"
                          prop="weightCbm"
                          style="width: 120px;margin-left: 10px;"
                        >
                          <el-input
                            v-model="item.weightCbm"
                            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                            placeholder="自动计算"
                            disabled
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          prop="freightClass"
                          style="width: 130px;"
                        >
                          <div class="calculate">
                            <img src="@/assets/images/order/calculate.png" alt="" />
                            计算货运等级
                          </div>
                          <div class="freight_class flex_c_c" @click="handleFreightClass(item)">
                            <span v-if="!item.freightClass">计算</span>
                            <span v-else>Class：{{ item.freightClass }}</span>
                          </div>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                </template>
              </template>
              <template v-else>
                <el-form
                  ref="product"
                  :model="product"
                  :rules="productRules"
                  label-position="top"
                >
                  <div class="product_panel">
                    <el-form-item
                      label="品名"
                      prop="productName"
                      style="width: 290px;"
                    >
                      <el-input
                        v-model="product.productName"
                        placeholder="请输入品名"
                        :disabled="disabled"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="!form.type"
                      label="柜型"
                      prop="packingCode"
                      style="width: 240px;"
                    >
                      <el-select
                        v-model="product.packingCode"
                        placeholder="请选择"
                        :disabled="disabled"
                        style="width: 240px;"
                      >
                        <el-option
                          v-for="item in config.containertypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-else
                      label="方数"
                      prop="weightCbm"
                      style="width: 240px;"
                    >
                      <el-input
                        v-model="product.weightCbm"
                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        style="width: 240px;"
                        placeholder="请输入"
                        :disabled="disabled"
                      >
                        <div slot="suffix" style="margin-right: 10px;color: #666666;">CBM</div>
                      </el-input>
                    </el-form-item>
                    <el-form-item
                      label="总重量"
                      prop="weight"
                      style="width: 240px;"
                      :rules="[
                        { required: true, message: '请输入总重量', trigger: 'blur' }
                      ]"
                    >
                      <el-input
                        v-model="product.weight"
                        placeholder="请输入"
                        :disabled="disabled"
                      >
                        <div class=""></div>
                      </el-input>
                    </el-form-item>
                    <el-form-item
                      label="是否危险品"
                      prop="isDangerous"
                      style="width: 100px;"
                    >
                      <el-switch
                        v-model="product.isDangerous"
                        :disabled="disabled"
                        :active-text="product.isDangerous ? '是' : '否'">
                      </el-switch>
                    </el-form-item>
                  </div>
                </el-form>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="panel">
              <div class="panel_title panel_title2 flex_c_c">报价结果</div>
              <template v-for="(item, $index) in priceList">
                <div :key="$index" class="price_item flex_c_c">
                  <div class="price_data">
                    <span class="price_title">
                      <img src="@/assets/images/order/date.png">预估时效
                    </span>
                    <span class="price_info">
                      {{ item.limitation }} 天
                    </span>
                  </div>
                  <div class="price_validity">
                    <span class="price_title">
                      <img src="@/assets/images/order/validity.png">报价有效期
                    </span>
                    <span class="price_info">
                      {{ item.validityDateStart | formatDate }} ~ {{ item.validityDateEnd | formatDate }}
                    </span>
                  </div>
                  <div class="price_remarks">
                    <span class="price_title">
                      <img src="@/assets/images/order/remark.png">报价备注
                    </span>
                    <span class="price_info">
                      {{ item.remarks }}
                    </span>
                  </div>
                  <div class="price_offer">
                    <span class="price_title">
                      <img src="@/assets/images/order/offer.png">报价{{ rowData.pickUpType === 1 ? '(纯运费)' : '' }}
                    </span>
                    <span class="price_info">
                      $ {{ item.price }}
                    </span>
                    <div v-if="rowData.pickUpType === 1" class="price_file">
                      <span @click="download(item.fileGuid)"><img src="@/assets/images/bill/download.png">下载</span>
                      <span @click="preview(item.fileGuid)"><i class="el-icon-zoom-in"></i>预览</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div v-if="!disabled && active === '0'" class="footer flex_c_c">
        <div v-loading="loading" class="ok flex_c_c" @click="ok">保存</div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import CustomSelect from './CustomSelect.vue'
import { mapGetters } from 'vuex'
import { getAddressFill } from '@/api/basic'
import config from '@/utils/config'
import {
  addOrUpdateTruckQuote,
  getTruckQuoteProducts,
  getTruckQuotePriceList
} from '@/api/fee'
export default {
  components: {
    Drawer,
    CustomSelect
  },
  filters: {
    formatDate(v) {
      return v ? v.slice(0, v.length - 9) : ''
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    shipOptions: {
      type: Array,
      default: () => []
    },
    airOptions: {
      type: Array,
      default: () => []
    },
    amsWarehouseList: {
      type: Array,
      default: () => []
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    isAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      config,
      form: {
        type: 0,
        isCustomer: true,
        isTailboard: true,
        customerName: '',
        customerId: '',
        transportName: '',
        arrivedAddressType: '',
        truckType: 'LTL',
        pickUpType: 1
      },
      rules: {
        type: [{ required: true }],
        isCustomer: [{ required: true }],
        pickUpType: [{ required: true }],
        truckType: [{ required: true, message: '请选择卡车类型', trigger: 'change' }],
        isTailboard: [{ required: true }],
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        transportName: [
          { required: true, message: '请选择港口', trigger: 'change' }
        ]
      },
      tabItems: [
        { name: '海运', icon: 'ship' },
        { name: '空运', icon: 'plane' }
      ],
      showPanel: false,
      isAmazon: true,
      pickUpAddress: {
        code: '',
        address1: '',
        city: '',
        stateProvinceCode: '',
        postalCode: ''
      },
      arrivedAddress: {
        code: '',
        address1: '',
        city: '',
        stateProvinceCode: '',
        postalCode: ''
      },
      arrivedRules: {
        code: [{ required: true, message: '请选择仓库代码', trigger: 'change' }],
        address1: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        stateProvinceCode: [
          { required: true, message: '请输入省州', trigger: 'blur' }
        ],
        postalCode: [
          { required: true, message: '请输入邮编', trigger: 'blur' }
        ]
      },
      productList: [
        {
          productName: '',
          packingCode: 'PLT',
          palletType: '自定义',
          unitType: 1,
          qty: '',
          weight: '',
          length: '',
          width: '',
          height: '',
          weightCbm: '',
          freightClass: '',
          isDangerous: false
        }
      ],
      productRules: {
        // productName: [
        //   { required: true, message: '请输入品名', trigger: 'blur' }
        // ],
        qty: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: '请输入单件重量', trigger: 'blur' }
        ],
        length: [
          { required: true, message: '请输入长度', trigger: 'change' }
        ],
        width: [
          { required: true, message: '请输入宽度', trigger: 'change' }
        ],
        height: [
          { required: true, message: '请输入高度', trigger: 'change' }
        ],
        weightCbm: [
          { required: true, message: '请输入方数', trigger: 'blur' }
        ],
        packingCode: [
          { required: true }
        ],
        isDangerous: [
          { required: true }
        ],
        unitType: [
          { required: true }
        ]
      },
      kabanOptions: config.palletOptions,
      unitTypeOptions: config.unitTypeOptions,
      loading: false,
      active: '0',
      priceList: [],
      product: {
        productName: '',
        packingCode: '20GP',
        palletType: '自定义',
        unitType: 1,
        qty: 1,
        weight: '',
        length: 0,
        width: 0,
        height: 0,
        weightCbm: 0,
        freightClass: 0,
        isDangerous: false
      },
      checkedArrived: [],
      arrivedAddressArr: [
        { id: 1, value: '商业地址带装卸台' },
        { id: 2, value: '住宅地址' },
        { id: 3, value: '商业地址不带装卸台' },
        { id: 4, value: 'CFS仓库' }
      ]
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    transportOptions() {
      const shipFiletrArr = ['USLGB', 'USNYK', 'USOAK', 'USCHI']
      const airFiletrArr = ['USLAX', 'USJFK', 'USORD', 'USEWR']
      const shipOptions = this.shipOptions.filter((a) =>
        shipFiletrArr.includes(a.value.split('-')[0])
      )
      const airOptions = this.airOptions.filter((a) =>
        airFiletrArr.includes(a.value.split('-')[0])
      )
      return (
        (this.form.type === 0 && shipOptions) ||
        (this.form.type === 1 && airOptions)
      )
    },
    disabled() {
      if (this.rowData.id && this.rowData.status !== 0) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    'form.transportName'(v) {
      if (v && this.form.customerName) {
        this.showPanel = true
      } else {
        this.showPanel = false
      }
    },
    'form.customerName'(v) {
      if (v && this.form.transportName) {
        this.showPanel = true
      } else {
        this.showPanel = false
      }
    },
    'arrivedAddress.code'(val) {
      if (val) {
        const arrivedAddress = this.amsWarehouseList.find(
          (a) => a.label === val
        )
        const {
          addressLine1: address1,
          cityName: city,
          stateRegion: stateProvinceCode,
          postalCode
        } = arrivedAddress
        this.arrivedAddress = {
          address1,
          city,
          stateProvinceCode,
          postalCode,
          code: val
        }
      }
    },
    isAmazon(v) {
      const obj = {
        code: '',
        address1: '',
        city: '',
        stateProvinceCode: '',
        postalCode: ''
      }
      if (!this.rowData.id) {
        !v && this.handleArrivedChange(true, '')
        this.arrivedAddress = obj
        return
      }
      const { code } = this.rowData.arrivedAddress
      v && code && (this.arrivedAddress = this.rowData.arrivedAddress)
      v && !code && (this.arrivedAddress = obj)
      !v && !code && (this.arrivedAddress = this.rowData.arrivedAddress)
      !v && code && (this.arrivedAddress = obj)
      !v && this.handleArrivedChange(true, this.rowData.arrivedAddressType)
    },
    async rowData(v) {
      if (v.id) {
        this.form = {
          ...v,
          isCustomer: !!v.customerId
        }
        const values = this.form.transportName.split('-')
        this.pickUpAddress = v.pickUpAddress ? v.pickUpAddress : this.mapAddress(values[0])
        this.arrivedAddress = v.arrivedAddress
        !v.arrivedAddress.code && (this.isAmazon = false)
        !this.isAmazon && this.handleArrivedChange(true, v.arrivedAddressType)
        this.getPromiseList()
        this.getList()
      } else if (!v.id && !this.isAdd) {
        this.reset()
      }
    }
  },
  mounted() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
      this.active = '0'
    },
    async getList() {
      try {
        const res = await getTruckQuotePriceList({ truckQuoteId: this.rowData.id })
        if (res.success) {
          this.priceList = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getPromiseList() {
      try {
        const res = await getTruckQuoteProducts({ truckQuoteId: this.rowData.id })
        if (res.success) {
          if (this.form.pickUpType === 2) {
            this.productList = res.data
          } else {
            this.product = res.data[0]
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async ok() {
      const productFormArr = []
      let flag = true
      if (this.form.pickUpType === 2) {
        for (let i = 0; i < this.productList.length; i++) {
          productFormArr.push(this.$refs[`product${i}`][0])
          const res = await this.handleFreightClass(this.productList[i])
          if (!res) {
            flag = false
            break
          }
        }
      }
      if (!flag) return
      const form1 = this.$refs.form1
      const form2 = this.$refs.form2
      const form3 = this.form.pickUpType === 2 ? productFormArr : this.$refs.product
      const formArr = [form1, form2]
      this.form.pickUpType === 2 ? formArr.push(...form3) : formArr.push(form3)
      this.loading = true
      Promise.all(formArr.map(this.getFormPromise)).then(async(res) => {
        const validateResult = res.every((item) => !!item)
        if (validateResult) {
          const arrivedAddressType = this.form.arrivedAddressType
          if (arrivedAddressType === '住宅地址') {
            this.$message.error('住宅地址必须要尾板')
            this.loading = false
            return
          }
          if (!arrivedAddressType && !this.isAmazon) {
            this.$message.error('需要选择一个派送地址类型')
            this.loading = false
            return
          }
          const params = {
            ...this.form,
            pickUpAddress: this.form.pickUpType === 2 ? this.pickUpAddress : '',
            arrivedAddress: this.arrivedAddress,
            productList: this.form.pickUpType === 2 ? this.productList : [this.product],
            arrivedAddressType: arrivedAddressType || '1'
          }
          try {
            const result = await addOrUpdateTruckQuote(params)
            this.loading = false
            if (result.success) {
              this.$emit('ok')
              this.reset()
              this.$message.success('保存成功')
            } else {
              this.$message.error(result.errorMessage)
            }
          } catch (error) {
            this.loading = false
            console.error(error)
          }
        } else {
          this.loading = false
          this.$message.error('表单未校验通过')
        }
      })
    },
    reset() {
      this.form = {
        type: 0,
        isCustomer: true,
        isTailboard: true,
        customerName: '',
        customerId: '',
        transportName: '',
        arrivedAddressType: '',
        truckType: 'LTL',
        pickUpType: 1
      }
      this.pickUpAddress = {
        code: '',
        address1: '',
        city: '',
        stateProvinceCode: '',
        postalCode: ''
      }
      this.arrivedAddress = {
        code: '',
        address1: '',
        city: '',
        stateProvinceCode: '',
        postalCode: ''
      }
      this.productList = [
        {
          productName: '',
          packingCode: 'PLT',
          palletType: '自定义',
          unitType: 1,
          qty: '',
          weight: '',
          length: '',
          width: '',
          height: '',
          weightCbm: '',
          freightClass: '',
          isDangerous: false
        }
      ]
      this.product = {
        productName: '',
        packingCode: '20GP',
        palletType: '自定义',
        unitType: 1,
        qty: 1,
        weight: '',
        length: 0,
        width: 0,
        height: 0,
        weightCbm: 0,
        freightClass: 0,
        isDangerous: false
      }
      this.isAmazon = true
      this.active = '0'
    },
    handleClickTab(index) {
      if (this.rowData.id && this.rowData.status !== 0) return
      this.form.type = index
      this.form.transportName = ''
      this.product = {
        productName: '',
        packingCode: '20GP',
        palletType: '自定义',
        unitType: 1,
        qty: 1,
        weight: '',
        length: 0,
        width: 0,
        height: 0,
        weightCbm: 0,
        freightClass: 0,
        isDangerous: false
      }
    },
    customerChange(v) {
      const customerId = this.companyDrapdown.find((a) => a.label === v).value
      this.form.customerId = customerId
    },
    handletruckType(v) {
      if (v === `53'FTL`) {
        this.form.isTailboard = false
        this.form.arrivedAddressType = ''
      }
    },
    handleCustomer(v) {
      !v && (this.form.customerName = '')
    },
    handleTransport(v) {
      const values = v.split('-')
      this.pickUpAddress = this.mapAddress(values[0])
    },
    mapAddress(name) {
      const arr = [
        [
          (name) => name === 'USLGB',
          () => {
            return {
              address1: '168 S Brea Canyon Rd',
              city: 'City of Industry',
              stateProvinceCode: 'CA',
              postalCode: '91789'
            }
          }
        ],
        [
          (name) => name === 'USNYK',
          () => {
            return {
              address1: '24 abeel road Monroe township',
              city: 'NJ',
              stateProvinceCode: 'NY',
              postalCode: '08831'
            }
          }
        ],
        [
          (name) => name === 'USOAK',
          () => {
            return {
              address1: '25503 Industrial Blvd, Dock 25~50',
              city: 'Hayward',
              stateProvinceCode: 'CA',
              postalCode: '94545'
            }
          }
        ],
        [
          (name) => name === 'USCHI',
          () => {
            return {
              address1: '1325 Remington Blvd, Ste B',
              city: 'Bolingbrook',
              stateProvinceCode: 'IL',
              postalCode: '60490'
            }
          }
        ],
        [
          (name) => name === 'USLAX',
          () => {
            return {
              address1: '168 S Brea Canyon Rd',
              city: 'City of Industry',
              stateProvinceCode: 'CA',
              postalCode: '91789'
            }
          }
        ],
        [
          (name) => name === 'USORD',
          () => {
            return {
              address1: '476 Thomas Drive',
              city: 'Bensenville',
              stateProvinceCode: 'IL',
              postalCode: '60106'
            }
          }
        ],
        [
          (name) => name === 'USJFK',
          () => {
            return {
              address1: '167-14 146th RD',
              city: 'JAMAICA',
              stateProvinceCode: 'NY',
              postalCode: '11434'
            }
          }
        ],
        [
          (name) => name === 'USEWR',
          () => {
            return {
              address1: '167-14 146th RD',
              city: 'JAMAICA',
              stateProvinceCode: 'NY',
              postalCode: '11434'
            }
          }
        ]
      ]
      return arr.find((a) => a[0](name))[1]()
    },
    getFormPromise(form) {
      return new Promise((resolve) => {
        form.validate((res) => {
          resolve(res)
        })
      })
    },
    async querySearchAsync(queryString, cb) {
      try {
        const res = await getAddressFill({
          countryCode: 'US',
          postalCode: queryString
        })
        if (res.success) {
          const arr = res.data.map((item) => {
            return {
              value: item.postalCode,
              address: `${item.cityName}, ${item.provinceCode},${item.postalCode}`
            }
          })
          cb(arr)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSelect(item) {
      const city = item.address.split(',')[0]
      const provinceName = item.address.split(',')[1].trim()
      this.arrivedAddress.stateProvinceCode = provinceName
      this.arrivedAddress.city = city
    },
    resultClass(density) {
      const describeForNameMap = [
        [(density) => density < 3, () => 300],
        [(density) => density < 4, () => 250],
        [(density) => density < 5, () => 200],
        [(density) => density < 6, () => 175],
        [(density) => density < 7, () => 150],
        [(density) => density < 8, () => 125],
        [(density) => density < 9, () => 110],
        [(density) => density < 10.5, () => 100],
        [(density) => density < 12, () => 92.5],
        [(density) => density < 13.5, () => 85],
        [(density) => density < 15, () => 77.5],
        [(density) => density < 22.5, () => 70],
        [(density) => density < 30, () => 65],
        [(density) => density < 35, () => 60],
        [(density) => density < 50, () => 55]
      ]
      let str // 存储判断结果
      const getDescribe = describeForNameMap.find((item) => item[0](density))
      if (getDescribe) {
        str = getDescribe[1]()
      } else {
        str = 50
      }
      return str
    },
    handleSelectKb(index, item) {
      const { palletType } = item
      this.computeCm(item)
      if (palletType !== '自定义') {
        const values = palletType.split('*')
        this.productList[index].length = values[0]
        this.productList[index].width = values[1]
      } else {
        this.productList[index].length = ''
        this.productList[index].width = ''
      }
    },
    newadd() {
      const product = {
        productName: '',
        packingCode: 'PLT',
        palletType: '自定义',
        unitType: 1,
        qty: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        weightCbm: '',
        freightClass: '',
        isDangerous: false
      }
      this.productList.push(product)
    },
    deleteProductL(index) {
      this.productList.splice(index, 1)
    },
    handleFreightClass(item) {
      const { length, width, height, weight, qty } = item
      if (
        length !== '' &&
        width !== '' &&
        height !== '' &&
        weight !== '' &&
        qty !== ''
      ) {
        const tiji =
          Number(length) *
          0.0833333 *
          (Number(width) * 0.0833333) *
          (Number(height) * 0.0833333)
        const zhongliang = (Number(weight) * 2.2046226) / qty
        const density = Number(zhongliang) / Number(tiji)
        item.freightClass = this.resultClass(density)
        return true
      } else {
        this.$message.warning('请填写完整当前货物信息')
        return false
      }
    },
    handlePacking(v, item) {
      if (v === 'PKG') {
        item.palletType = '自定义'
        item.length = ''
        item.width = ''
      }
    },
    computeCm(item) {
      let { length, width, height } = item
      const qty = item.qty
      if (!length || !width || !height || !qty) {
        return
      }
      if (item.unitType === 0) {
        length = length * 2.54
        width = width * 2.54
        height = height * 2.54
      }
      // 计算体积,单位立方厘米
      const volumeCm3 = length * width * height
      // 转换为立方米
      const volumeM3 = volumeCm3 / 1000000
      // 取整输出
      // const volumeM = Math.round(volumeM3)
      const volumeM = volumeM3
      item.weightCbm = (+qty * volumeM).toFixed(2)
    },
    handleArrivedChange(v, label) {
      v && (this.form.arrivedAddressType = label)
      !v && (this.form.arrivedAddressType = '')
      if (v) {
        this.$nextTick(() => {
          // 清空其他选中
          this.arrivedAddressArr.forEach(item => {
            const checkedbox = this.$refs[`arrivedAddress${item.id}`]
            if (item.value !== label) {
              checkedbox.model = [label]
            }
          })
        })
      }
    },
    download(id) {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href =
          `/backEnd/api/${config.supplyCode}/File/downLoadFile?guid=` + id
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    },
    preview(id) {
      window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  position: relative;
  width: 100%;
  height: 100%;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    color: #2395f3;
    padding: 0 20px;
    border-bottom: 1px solid #f7f7f7;
    box-sizing: border-box;
    .closeIcon {
      color: #333333;
      font-size: 20px;
      cursor: pointer;
    }
    .tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      padding-right: 20px;
      box-sizing: border-box;
      &:deep(.el-tabs__header) {
        margin: 15px 0;
      }
      &:deep(.el-tabs--card > .el-tabs__header) {
        border-bottom: 0;
      }
      &:deep(.el-tabs__nav) {
        border: 0;
      }
      &:deep(.el-tabs__item) {
        height: 24px;
        line-height: 24px;
        border: 0;
      }
      &:deep(.el-tabs__item.is-active) {
        color: #2395f3;
      }
    }
  }
  .log-body {
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding-bottom: 110px;
    box-sizing: border-box;
    .content {
      width: calc(100% - 40px);
      height: auto;
      margin: 0 auto;
      padding-top: 15px;
      box-sizing: border-box;
    }
    .panel {
      position: relative;
      width: 930px;
      .panel_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      }
      .panel_title {
        width: 120px;
        height: 30px;
        background: #feaa4f;
        border-radius: 4px 15px 15px 4px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 15px;
        z-index: 1;
      }
      .panel_title2 {
        background: #2395F3;
      }
      .newadd {
        width: 80px;
        height: 30px;
        background: #35ba83;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        z-index: 1;
      }
      .el-form-item {
        width: 290px;
      }
      .pickup_box {
        width: 445px;
        min-height: 60px;
        height: auto;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        padding: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        color: #666666;
      }
      .arrived {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 30px;
        height: 40px;
      }
      .product_list {
        position: relative;
        width: 100%;
        height: 183px;
        background: #f7f7f7;
        border-radius: 4px 4px 4px 4px;
        padding: 10px 10px 0 10px;
        box-sizing: border-box;
        .delete-goods {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          background: #FE453D;
          border-radius: 0px 4px 0px 4px;
          cursor: pointer;
          .el-icon-delete {
            font-size: 15px;
            color: #ffffff;
          }
        }
        & + .product_list {
          margin-top: 15px;
        }
        .el-form-item:nth-child(1) {
          margin-right: 10px;
        }
        .el-form-item + .el-form-item {
          margin-left: 10px;
        }
        .input {
          margin-left: 10px;
          label {
            line-height: 30px;
          }
          .widthMax {
            display: flex;
          }
          .require {
            color: #ff7444;
          }
          /deep/ .input1 {
            input {
              border-right: 0px;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
          /deep/ .input2 {
            input {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
            }
          }
          .suffix {
            width: 40px;
            height: 40px;
            background: #EEEEEE;
            border-radius: 0px 4px 4px 0px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
        .calculate {
          display: flex;
          align-items: center;
          height: 30px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #2395F3;
          img {
            margin-right: 5px;
          }
        }
        .freight_class {
          width: 130px;
          height: 40px;
          background: rgba(35, 149, 243, .1);
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #2395F3;
          cursor: pointer;
        }
      }
      .product_panel {
        display: flex;
        .el-form-item + .el-form-item {
          margin-left: 10px;
        }
      }
      .price_item {
        width: 100%;
        height: 140px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
        border-radius: 4px 4px 4px 4px;
        padding: 16px;
        box-sizing: border-box;
        & + .price_item {
          margin-top: 20px;
        }
        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          height: 100%;
          & .price_title img {
            margin-right: 4px;
          }
        }
        .price_data {
          position: relative;
          width: 164px;
          &::after {
            content: '';
            position: absolute;
            display: block;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 40px;
            border-left: 1px solid #EEEEEE;
          }
          .price_info {
            font-size: 18px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #35BA83;
          }
        }
        .price_validity {
          position: relative;
          width: 245px;
          &::after {
            content: '';
            position: absolute;
            display: block;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 40px;
            border-left: 1px solid #EEEEEE;
          }
          .price_info {
            font-size: 14px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #2395F3;
          }
        }
        .price_remarks {
          flex: 1;
          .price_info {
            font-size: 14px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #666666;
          }
        }
        .price_offer {
          width: 160px;
          background: #F7F7F7;
          border-radius: 4px 4px 4px 4px;
          .price_info {
            font-size: 18px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #FE453D;
          }
          .price_file {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 140px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #2395F3;
            & span {
              display: flex;
              align-items: center;
              cursor: pointer;
              & i {
                font-size: 16px;
              }
            }
          }
        }
        .price_title {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .line::after {
      content: "";
      position: absolute;
      top: 15px;
      display: block;
      width: 100%;
      opacity: 0.5;
      border-top: 1px dashed #feaa4f;
      box-sizing: border-box;
      z-index: 0;
    }
    .tab {
      display: flex;
      width: 100%;
      .radio {
        display: flex;
        align-items: center;
        position: relative;
        width: 200px;
        height: 40px;
        background: #ffffff;
        color: #999999;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #eeeeee;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        .round {
          position: absolute;
          right: 20px;
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #cdcdcd;
        }
        .icon {
          margin-left: 20px;
          margin-right: 5px;
          font-size: 20px;
        }
      }
      .radio:first-child {
        margin-right: 10px;
      }
      .active {
        border: 1px solid #2395f3;
        color: #2395f3;
        .round {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #2395f3;
          border: 0;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 42px;
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
    z-index: 2;
    .ok {
      width: 140px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
