var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, withHeader: false, size: 970 },
      on: { close: _vm.handleClose },
    },
    [
      _c("div", { staticClass: "view" }, [
        _c(
          "div",
          {
            staticClass: "title",
            style: { padding: _vm.isAdd ? "0 20px" : "0 20px 0 0" },
          },
          [
            _vm.isAdd
              ? _c("span", [_vm._v("卡派询价")])
              : _c(
                  "div",
                  { staticClass: "tag" },
                  [
                    _c(
                      "el-tabs",
                      {
                        attrs: { type: "card" },
                        model: {
                          value: _vm.active,
                          callback: function ($$v) {
                            _vm.active = $$v
                          },
                          expression: "active",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "详情", name: "0" },
                        }),
                        _vm.rowData.status !== 0
                          ? _c("el-tab-pane", {
                              attrs: { label: "报价", name: "1" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
            _c("i", {
              staticClass: "el-icon-close closeIcon",
              on: { click: _vm.handleClose },
            }),
          ]
        ),
        _c("div", { staticClass: "log-body" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _vm.active === "0"
                ? [
                    _c(
                      "el-form",
                      {
                        ref: "form1",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rules,
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "panel" },
                          [
                            _c("div", { staticClass: "panel_title flex_c_c" }, [
                              _vm._v("选择派送方式"),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "pickUpType" } },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              label: 1,
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value: _vm.form.pickUpType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "pickUpType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.pickUpType",
                                            },
                                          },
                                          [_vm._v("直送")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              label: 2,
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value: _vm.form.pickUpType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "pickUpType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.pickUpType",
                                            },
                                          },
                                          [_vm._v("回仓")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "panel line",
                            staticStyle: { "margin-bottom": "20px" },
                          },
                          [
                            _c("div", { staticClass: "panel_title flex_c_c" }, [
                              _vm._v("填写卡派信息"),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "选择类型",
                                          prop: "type",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tab" },
                                          _vm._l(
                                            _vm.tabItems,
                                            function (item, $index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: $index,
                                                  class: [
                                                    "radio",
                                                    _vm.form.type === $index
                                                      ? "active"
                                                      : "",
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleClickTab(
                                                        $index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      className: "icon",
                                                      iconClass: item.icon,
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    { staticClass: "round" },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/order/selected.png"),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "客户是否开户",
                                          prop: "isCustomer",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            on: { input: _vm.handleCustomer },
                                            model: {
                                              value: _vm.form.isCustomer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "isCustomer",
                                                  $$v
                                                )
                                              },
                                              expression: "form.isCustomer",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: true,
                                                  disabled: _vm.disabled,
                                                },
                                              },
                                              [_vm._v("已开户")]
                                            ),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: false,
                                                  disabled: _vm.disabled,
                                                },
                                              },
                                              [_vm._v("未开户")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "客户名称",
                                          prop: "customerName",
                                        },
                                      },
                                      [
                                        _vm.form.isCustomer
                                          ? _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  change: _vm.customerChange,
                                                },
                                                model: {
                                                  value: _vm.form.customerName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "customerName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.customerName",
                                                },
                                              },
                                              _vm._l(
                                                _vm.companyDrapdown,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.label,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.form.customerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "customerName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.customerName",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: !_vm.form.type
                                            ? "港口"
                                            : "机场",
                                          prop: "transportName",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择",
                                              disabled: _vm.disabled,
                                            },
                                            on: { change: _vm.handleTransport },
                                            model: {
                                              value: _vm.form.transportName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "transportName",
                                                  $$v
                                                )
                                              },
                                              expression: "form.transportName",
                                            },
                                          },
                                          _vm._l(
                                            _vm.transportOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.form.pickUpType === 2
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "卡车类型",
                                              prop: "truckType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  input: _vm.handletruckType,
                                                },
                                                model: {
                                                  value: _vm.form.truckType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "truckType",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.truckType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "LTL",
                                                      disabled: _vm.disabled,
                                                    },
                                                  },
                                                  [_vm._v("LTL")]
                                                ),
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "53'FTL",
                                                      disabled: _vm.disabled,
                                                    },
                                                  },
                                                  [_vm._v("53'FTL")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.form.pickUpType === 2
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "是否需要尾板",
                                              prop: "isTailboard",
                                            },
                                          },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-text": _vm.form
                                                  .isTailboard
                                                  ? "是"
                                                  : "否",
                                                disabled:
                                                  _vm.form.truckType ===
                                                    `53'FTL` || _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.form.isTailboard,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "isTailboard",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.isTailboard",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPanel && _vm.form.pickUpType === 2,
                            expression: "showPanel && form.pickUpType === 2",
                          },
                        ],
                        staticClass: "panel line",
                        staticStyle: { "margin-bottom": "40px" },
                      },
                      [
                        _c("div", { staticClass: "panel_title flex_c_c" }, [
                          _vm._v("提货地址"),
                        ]),
                        _c("div", { staticClass: "pickup_box" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.pickUpAddress.address1)),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.pickUpAddress.city +
                                    "," +
                                    _vm.pickUpAddress.stateProvinceCode +
                                    ","
                                ) +
                                " "
                            ),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.pickUpAddress.postalCode)),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPanel,
                            expression: "showPanel",
                          },
                        ],
                        staticClass: "panel line",
                        staticStyle: { "margin-bottom": "20px" },
                      },
                      [
                        _c("div", { staticClass: "panel_title flex_c_c" }, [
                          _vm._v("派送地址"),
                        ]),
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "10px" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.isAmazon,
                                  callback: function ($$v) {
                                    _vm.isAmazon = $$v
                                  },
                                  expression: "isAmazon",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: true,
                                      disabled: _vm.disabled,
                                    },
                                  },
                                  [_vm._v("亚马逊仓")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: false,
                                      disabled: _vm.disabled,
                                    },
                                  },
                                  [_vm._v("非亚马逊仓")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form",
                          {
                            ref: "form2",
                            attrs: {
                              model: _vm.arrivedAddress,
                              rules: _vm.arrivedRules,
                              "label-position": "top",
                            },
                          },
                          [
                            _vm.isAmazon
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "40px" },
                                      attrs: {
                                        label: "仓库代码",
                                        prop: "code",
                                      },
                                    },
                                    [
                                      _c("custom-select", {
                                        staticStyle: { width: "445px" },
                                        attrs: {
                                          selectItems: _vm.amsWarehouseList,
                                          disabled: _vm.disabled,
                                          dropdownWidth: "445",
                                          placeholder: "请选择",
                                          label: "仓库编码",
                                        },
                                        model: {
                                          value: _vm.arrivedAddress.code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.arrivedAddress,
                                              "code",
                                              $$v
                                            )
                                          },
                                          expression: "arrivedAddress.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "邮编",
                                                prop: "postalCode",
                                              },
                                            },
                                            [
                                              _c("el-autocomplete", {
                                                staticStyle: { width: "290px" },
                                                attrs: {
                                                  oninput:
                                                    "value=value.replace(/[^0-9a-zA-Z-]/g,'')",
                                                  placeholder:
                                                    "输入数字、英文和横杠字符，最多12位",
                                                  maxlength: 12,
                                                  "fetch-suggestions":
                                                    _vm.querySearchAsync,
                                                  "trigger-on-focus": false,
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  select: _vm.handleSelect,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.value
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "addr",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.address
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  922005587
                                                ),
                                                model: {
                                                  value:
                                                    _vm.arrivedAddress
                                                      .postalCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.arrivedAddress,
                                                      "postalCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "arrivedAddress.postalCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "省州",
                                                prop: "stateProvinceCode",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入省州",
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.arrivedAddress
                                                      .stateProvinceCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.arrivedAddress,
                                                      "stateProvinceCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "arrivedAddress.stateProvinceCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "城市",
                                                prop: "city",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入城市",
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.arrivedAddress.city,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.arrivedAddress,
                                                      "city",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "arrivedAddress.city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 16 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "详细地址",
                                                prop: "address1",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "600px" },
                                                attrs: {
                                                  placeholder: "请输入详细地址",
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.arrivedAddress.address1,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.arrivedAddress,
                                                      "address1",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "arrivedAddress.address1",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              staticClass: "arrived",
                                              model: {
                                                value: _vm.checkedArrived,
                                                callback: function ($$v) {
                                                  _vm.checkedArrived = $$v
                                                },
                                                expression: "checkedArrived",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("el-checkbox", {
                                                    ref: "arrivedAddress1",
                                                    attrs: {
                                                      label: "商业地址带装卸台",
                                                      disabled: _vm.disabled,
                                                    },
                                                    on: {
                                                      change: (v) =>
                                                        _vm.handleArrivedChange(
                                                          v,
                                                          "商业地址带装卸台"
                                                        ),
                                                    },
                                                  }),
                                                  _c("el-checkbox", {
                                                    ref: "arrivedAddress2",
                                                    attrs: {
                                                      label: "住宅地址",
                                                      disabled:
                                                        _vm.form.truckType ===
                                                          `53'FTL` ||
                                                        _vm.disabled,
                                                    },
                                                    on: {
                                                      change: (v) =>
                                                        _vm.handleArrivedChange(
                                                          v,
                                                          "住宅地址"
                                                        ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _c("el-checkbox", {
                                                    ref: "arrivedAddress3",
                                                    attrs: {
                                                      label:
                                                        "商业地址不带装卸台",
                                                      disabled: _vm.disabled,
                                                    },
                                                    on: {
                                                      change: (v) =>
                                                        _vm.handleArrivedChange(
                                                          v,
                                                          "商业地址不带装卸台"
                                                        ),
                                                    },
                                                  }),
                                                  _c("el-checkbox", {
                                                    ref: "arrivedAddress4",
                                                    attrs: {
                                                      label: "CFS仓库",
                                                      disabled: _vm.disabled,
                                                    },
                                                    on: {
                                                      change: (v) =>
                                                        _vm.handleArrivedChange(
                                                          v,
                                                          "CFS仓库"
                                                        ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPanel,
                            expression: "showPanel",
                          },
                        ],
                        staticClass: "panel line",
                        staticStyle: { "margin-bottom": "20px" },
                      },
                      [
                        _c("div", { staticClass: "panel_header" }, [
                          _c(
                            "div",
                            {
                              staticClass: "panel_title flex_c_c",
                              staticStyle: { margin: "0" },
                            },
                            [_vm._v("货物信息")]
                          ),
                          !_vm.disabled && _vm.form.pickUpType === 2
                            ? _c(
                                "div",
                                {
                                  staticClass: "newadd flex_c_c",
                                  on: { click: _vm.newadd },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/order/addimg.png"),
                                    },
                                  }),
                                  _vm._v(" 新增 "),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm.form.pickUpType === 2
                          ? [
                              _vm._l(_vm.productList, function (item, $index) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: $index,
                                      staticClass: "product_list",
                                    },
                                    [
                                      $index && !_vm.disabled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "delete-goods flex_c_c",
                                              attrs: { title: "删除" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteProductL(
                                                    $index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form",
                                        {
                                          ref: `product${$index}`,
                                          refInFor: true,
                                          attrs: {
                                            model: _vm.productList[$index],
                                            rules: _vm.productRules,
                                            "label-position": "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "product_panel" },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "品名",
                                                    prop: "productName",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请输入品名",
                                                      disabled: _vm.disabled,
                                                    },
                                                    model: {
                                                      value: item.productName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "productName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.productName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "115px",
                                                  },
                                                  attrs: {
                                                    label: "包装类型",
                                                    prop: "packingCode",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      on: {
                                                        input: (v) =>
                                                          _vm.handlePacking(
                                                            v,
                                                            item
                                                          ),
                                                      },
                                                      model: {
                                                        value: item.packingCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "packingCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.packingCode",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "PLT",
                                                            disabled:
                                                              _vm.disabled,
                                                          },
                                                        },
                                                        [_vm._v("板")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "PKG",
                                                            disabled:
                                                              _vm.disabled,
                                                          },
                                                        },
                                                        [_vm._v("箱")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              item.packingCode === "PLT"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        width: "180px",
                                                      },
                                                      attrs: {
                                                        label: "卡板类型",
                                                        prop: "palletType",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请选择",
                                                            disabled:
                                                              _vm.disabled,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleSelectKb(
                                                                $index,
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.palletType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "palletType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.palletType",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.kabanOptions,
                                                          function (keys) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: keys.value,
                                                                attrs: {
                                                                  label:
                                                                    keys.label,
                                                                  value:
                                                                    keys.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "135px",
                                                  },
                                                  attrs: {
                                                    label: "单位",
                                                    prop: "unitType",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder: "请选择",
                                                        disabled: _vm.disabled,
                                                      },
                                                      on: {
                                                        change: () =>
                                                          _vm.computeCm(item),
                                                      },
                                                      model: {
                                                        value: item.unitType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "unitType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.unitType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.unitTypeOptions,
                                                      function (keys) {
                                                        return _c("el-option", {
                                                          key: keys.value,
                                                          attrs: {
                                                            label: keys.label,
                                                            value: keys.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "130px",
                                                  },
                                                  attrs: {
                                                    label: "是否危险品",
                                                    prop: "isDangerous",
                                                  },
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      "active-text":
                                                        item.isDangerous
                                                          ? "是"
                                                          : "否",
                                                    },
                                                    model: {
                                                      value: item.isDangerous,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "isDangerous",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.isDangerous",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "product_panel" },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "110px",
                                                    "margin-right": "0px",
                                                  },
                                                  attrs: {
                                                    label: "数量",
                                                    prop: "qty",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                      placeholder: "请输入",
                                                      disabled: _vm.disabled,
                                                    },
                                                    on: {
                                                      input: () =>
                                                        _vm.computeCm(item),
                                                    },
                                                    model: {
                                                      value: item.qty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "qty",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.qty",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "110px",
                                                  },
                                                  attrs: {
                                                    label: "单件重量",
                                                    prop: "weight",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                      placeholder: "请输入",
                                                      disabled: _vm.disabled,
                                                    },
                                                    model: {
                                                      value: item.weight,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "weight",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.weight",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "110px",
                                                  },
                                                  attrs: { label: "总重量" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      value:
                                                        item.qty * item.weight,
                                                      disabled: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "input" },
                                                [
                                                  _c("label", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "require",
                                                      },
                                                      [_vm._v("* ")]
                                                    ),
                                                    _vm._v("尺寸"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    { staticClass: "widthMax" },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticStyle: {
                                                            width: "80px",
                                                            margin: "0",
                                                          },
                                                          attrs: {
                                                            prop: "length",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "input1",
                                                            attrs: {
                                                              placeholder: "长",
                                                              disabled:
                                                                item.palletType !==
                                                                  "自定义" ||
                                                                _vm.disabled,
                                                              oninput:
                                                                "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                            },
                                                            on: {
                                                              input: () =>
                                                                _vm.computeCm(
                                                                  item
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                item.length,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "length",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.length",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticStyle: {
                                                            width: "80px",
                                                            margin: "0",
                                                          },
                                                          attrs: {
                                                            prop: "width",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "input1 input2",
                                                            attrs: {
                                                              placeholder: "宽",
                                                              disabled:
                                                                item.palletType !==
                                                                  "自定义" ||
                                                                _vm.disabled,
                                                              oninput:
                                                                "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                            },
                                                            on: {
                                                              input: () =>
                                                                _vm.computeCm(
                                                                  item
                                                                ),
                                                            },
                                                            model: {
                                                              value: item.width,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "width",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.width",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticStyle: {
                                                            width: "80px",
                                                            margin: "0",
                                                          },
                                                          attrs: {
                                                            prop: "height",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "input2 input1",
                                                            attrs: {
                                                              placeholder: "高",
                                                              oninput:
                                                                "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                              disabled:
                                                                _vm.disabled,
                                                            },
                                                            on: {
                                                              input: () =>
                                                                _vm.computeCm(
                                                                  item
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                item.height,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "height",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.height",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "suffix flex_c_c",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.unitType
                                                                ? "CM"
                                                                : "IN"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "120px",
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: {
                                                    label: "方数",
                                                    prop: "weightCbm",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                      placeholder: "自动计算",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value: item.weightCbm,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "weightCbm",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.weightCbm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "130px",
                                                  },
                                                  attrs: {
                                                    prop: "freightClass",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "calculate",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/order/calculate.png"),
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(" 计算货运等级 "),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "freight_class flex_c_c",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleFreightClass(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      !item.freightClass
                                                        ? _c("span", [
                                                            _vm._v("计算"),
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              "Class：" +
                                                                _vm._s(
                                                                  item.freightClass
                                                                )
                                                            ),
                                                          ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ]
                          : [
                              _c(
                                "el-form",
                                {
                                  ref: "product",
                                  attrs: {
                                    model: _vm.product,
                                    rules: _vm.productRules,
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "product_panel" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { width: "290px" },
                                          attrs: {
                                            label: "品名",
                                            prop: "productName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入品名",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value: _vm.product.productName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.product,
                                                  "productName",
                                                  $$v
                                                )
                                              },
                                              expression: "product.productName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      !_vm.form.type
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "240px" },
                                              attrs: {
                                                label: "柜型",
                                                prop: "packingCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "240px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请选择",
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.product.packingCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.product,
                                                        "packingCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "product.packingCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.config
                                                    .containertypeOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "240px" },
                                              attrs: {
                                                label: "方数",
                                                prop: "weightCbm",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "240px",
                                                  },
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                    placeholder: "请输入",
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.product.weightCbm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.product,
                                                        "weightCbm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "product.weightCbm",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                        color: "#666666",
                                                      },
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("CBM")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { width: "240px" },
                                          attrs: {
                                            label: "总重量",
                                            prop: "weight",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入总重量",
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder: "请输入",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.product.weight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.product,
                                                    "weight",
                                                    $$v
                                                  )
                                                },
                                                expression: "product.weight",
                                              },
                                            },
                                            [_c("div", {})]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            label: "是否危险品",
                                            prop: "isDangerous",
                                          },
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              "active-text": _vm.product
                                                .isDangerous
                                                ? "是"
                                                : "否",
                                            },
                                            model: {
                                              value: _vm.product.isDangerous,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.product,
                                                  "isDangerous",
                                                  $$v
                                                )
                                              },
                                              expression: "product.isDangerous",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "panel" },
                      [
                        _c(
                          "div",
                          { staticClass: "panel_title panel_title2 flex_c_c" },
                          [_vm._v("报价结果")]
                        ),
                        _vm._l(_vm.priceList, function (item, $index) {
                          return [
                            _c(
                              "div",
                              {
                                key: $index,
                                staticClass: "price_item flex_c_c",
                              },
                              [
                                _c("div", { staticClass: "price_data" }, [
                                  _c("span", { staticClass: "price_title" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/date.png"),
                                      },
                                    }),
                                    _vm._v("预估时效 "),
                                  ]),
                                  _c("span", { staticClass: "price_info" }, [
                                    _vm._v(
                                      " " + _vm._s(item.limitation) + " 天 "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "price_validity" }, [
                                  _c("span", { staticClass: "price_title" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/validity.png"),
                                      },
                                    }),
                                    _vm._v("报价有效期 "),
                                  ]),
                                  _c("span", { staticClass: "price_info" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            item.validityDateStart
                                          )
                                        ) +
                                        " ~ " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            item.validityDateEnd
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "price_remarks" }, [
                                  _c("span", { staticClass: "price_title" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/remark.png"),
                                      },
                                    }),
                                    _vm._v("报价备注 "),
                                  ]),
                                  _c("span", { staticClass: "price_info" }, [
                                    _vm._v(" " + _vm._s(item.remarks) + " "),
                                  ]),
                                ]),
                                _c("div", { staticClass: "price_offer" }, [
                                  _c("span", { staticClass: "price_title" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/offer.png"),
                                      },
                                    }),
                                    _vm._v(
                                      "报价" +
                                        _vm._s(
                                          _vm.rowData.pickUpType === 1
                                            ? "(纯运费)"
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "price_info" }, [
                                    _vm._v(" $ " + _vm._s(item.price) + " "),
                                  ]),
                                  _vm.rowData.pickUpType === 1
                                    ? _c("div", { staticClass: "price_file" }, [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.download(
                                                  item.fileGuid
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/bill/download.png"),
                                              },
                                            }),
                                            _vm._v("下载"),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.preview(
                                                  item.fileGuid
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-zoom-in",
                                            }),
                                            _vm._v("预览"),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
        ]),
        !_vm.disabled && _vm.active === "0"
          ? _c("div", { staticClass: "footer flex_c_c" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "ok flex_c_c",
                  on: { click: _vm.ok },
                },
                [_vm._v("保存")]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }